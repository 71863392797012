<template>
  <v-dialog v-model="value" persistent scrollable max-width="90vw">
  <v-card v-if="promotion">
    <v-card-title class="pa-0">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{newPromotion ? 'New Promotion' : promotion.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-row class="mt-4">
        <v-col class="col-9">
          <select-artist @onChange="handleArtistChange"></select-artist>
          <v-text-field outlined dense label="Title" v-model="promotion.title" :rules=[rules.required]></v-text-field>
          <v-autocomplete outlined dense :rules=[rules.imagesRequired] multiple v-model="values" :items="products" item-text="title" item-value="title" label="Select Images"  hide-selected return-object>
            <template v-slot:selection="{ item, index }" >
              <div class="d-flex align-center my-2 py-2">
                <v-img max-height="50px" max-width="50px" :src="item.imgUrl" /><span class="mx-2">{{ item.title }}</span>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div class="d-flex align-center py-2">
                <v-img max-height="50px" max-width="50px" :src="item.imgUrl" /><span class="mx-2">{{ item.title }}</span>
              </div>
            </template>
          </v-autocomplete>
             <draggable v-if="promotion.images" :list="promotion.images" class="d-flex flex-wrap" @end="onEnd">
          <v-card  v-for="(image, index) in promotion.images" :key="image.file" class="pa-4 ma-2">
            <v-icon style="position: absolute; top: 0; right: 0;" @click="onRemove(index)">mdi-delete</v-icon>
            <v-img width="150" height="150" contain class="ma-2" :src="image.imgUrl" ripple />
          </v-card>
          </draggable>
          <div class="mt-8" v-if="promotion.title">
            <label>HTML Block 1</label>
            <vue-editor
              class="mb-8"
              useCustomImageHandler
              @image-added="handleImageAdded"
              v-model="promotion.html.block1">
            </vue-editor>
            <label>HTML Block 2</label>
            <vue-editor v-model="promotion.html.block2"></vue-editor>
            <p>Use this feature to manage and promote feaured products ... possibly this duplicates the Promotions page</p>
          </div>
        </v-col>
        <v-col class="col-2 text-center">
          <h3 class="mb-4">Desktop</h3>
          <v-img src="@/assets/wireframes/new_artwork_promotion_email.png"></v-img>
        </v-col>
        <v-col class="col-1 text-center">
          <h3 class="mb-4">Mobile</h3>
          <v-img src="@/assets/wireframes/new_artwork_promotion_email_mobile.png"></v-img>
        </v-col>
        <v-col class="col-12">
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" class="mr-4" @click.native="savePromotion(true)">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import ProductApi from '@/api/admin/product'
import ArtistApi from '@/api/public/artist'
import PromotionApi from '@/api/promotion'
import draggable from 'vuedraggable'
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex"
import SelectArtist from "@/components/SelectArtist"
import axios from 'axios'
import _ from "lodash"
export default {
  props: {
    value: Boolean,
    data: Object,
  },
  components: {
    VueEditor,
    draggable,
    SelectArtist
  },
  data() {
    return {
      values: [],
      selectedProducts: [],
      products: [],
      newPromotion: true,
      promotion: this.data || { html: {} },
      rules: {
        imagesRequired: () => {
          return this.promotion.images?.length > 3 || 'At least 4 images are required for this promotion template'
        },
        required: (value) => !!value || 'Required.'
      },
      valid: true,
      processing: false,
      promotionType: null,
      mode: 'preview'
    }
  },
  async created() {
    if (this.data) {
      this.newPromotion = false
    }
    ProductApi.loadAllProducts().then((results) => {
      this.products = _.sortBy(results.data.map(product => {
        return {
          imgUrl: `${process.env.VUE_APP_IMG_URL}/300px/${product.productImage}`,
          title: product.title,
          artist: product.artist,
          product: product.variant,
          file: product.productImage
        }
      }), ["artist", "product"])
    }).catch(
      err => {
        console.log('err', err)
        this.loadingData = false
      }
    )
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters('reports', ['activeArtist']),
  },
  watch: {},
  methods: {
    onEnd() {
    },
    onRemove(img) {
      this.promotion.images.splice(img,1)
    },
    close() {
      this.$emit('close')
    },
    handleArtistChange(artist) {
      console.log(artist)
      ArtistApi.loadArtist(artist.artistCode).then((results) => {
      this.artist = results.data.artist
      this.artworks = results.data.artworks.map(artwork => {
        return {
          imgUrl: `${process.env.VUE_APP_IMG_URL}/300px/${artwork.artworkImage}`,
          title: artwork.title,
          region: artwork.region,
          subjectMatter: artwork.subjectMatter,
          file: artwork.artworkImage
        }
      })
    }).catch(
      err => {
        console.log('err', err)
        this.loadingData = false
      }
    )},
    savePromotion(close) {
      this.updating = true
      const rest = this.newPromotion ? 'createPromotion' : 'updatePromotion'
      PromotionApi[rest](this.promotion).then((results) => {
        // this.promotion = {...results.data }
        this.updating = false
      }).catch(
        err => {
          console.log('err', err)
          this.updating = false
        }
      )
      this.isDirty = false
      if (close) { this.close() }
    },
    handleImageAdded(file, Editor, cursorLocation) {
      console.log(file)
      const CLIENT_ID = '993793b1d8d3e2e'

      var formData = new FormData();
      formData.append('image', file)

      axios({
        url: 'https://api.imgur.com/3/image',
        method: 'POST',
        headers:{
          'Authorization': 'Client-ID ' + CLIENT_ID
        },
        data: formData
      })
      .then((result) => {
        console.log(result);
        let url = result.data.data.link
        Editor.insertEmbed(cursorLocation, 'image', url);
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-dialog__content {
  align-items: start;
  margin-top: 50px
}
</style>
